<template>
  <nav aria-labelledby="help-center-sidebar-title">
    <h3 id="help-center-sidebar-title" class="mb-4 title-2">
      {{ $t.helpCenterSidebarHeader }}
    </h3>
    <ul>
      <li v-for="({ url, title, children }, i) in links" :key="i" :class="brandClasses.listItem">
        <vf-accordion
          v-if="children?.length"
          :class="brandClasses.accordion"
          :open="isExpanded(url)"
          variant="aligned"
        >
          <template #toggle="{ expanded }">
            <span :class="brandClasses.accordionSpan">
              {{ title }}
              <vf-icon :dir="expanded ? 'up' : 'down'" name="chevron" :size="chevronSize" />
            </span>
          </template>
          <ul>
            <li v-for="(child, j) in children" :key="j" class="ml-2 mt-2 ">
              <base-link class="hover:underlined" exact-active-class="fw-bold" :to="child.url">
                {{ child.title }}
              </base-link>
            </li>
          </ul>
        </vf-accordion>
        <base-link
          v-else
          :class="[brandClasses.sidebarLink, route.path.endsWith(url) ? 'underlined fw-bold' : 'hover:fw-bold hover:underlined']"
          :data-scroll-el="useLocalisedRoute(url)"
          exact-active-class="fw-bold"
          :to="url"
        >
          {{ title }}
        </base-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import type { PageChildrenData } from '#root/api/clients/content/data-contracts'

const { brandClasses, chevronSize } = useAppConfig().pages.help.sidebar

const route = useRoute()
const { $t } = useNuxtApp()
const links = ref<Awaited<PageChildrenData['content']>>([])

try {
  const { getPageChildren } = useCms()
  const localisedHelp = useLocalisedPath('/help')
  links.value = await getPageChildren(localisedHelp.substring(1), { lvl2: true })
}
catch (err: any) {
  log.error(`[@domains/commerce/components/help/Sidebar.vue] Error: ${err.message}`)
}

const scrollToTop = () =>
  scrollTo({ top: 0, behavior: 'smooth' })

watch(route, scrollToTop)

const isExpanded = (url: string) => route.path.includes(url)
</script>
